import { createSelector } from '@ngrx/store';
import { PageState } from './page.state';


export const selectPageState = (state: { page: PageState }) => state.page;

export const selectTitle = createSelector(
  selectPageState,
  (state: PageState) => state.title
);

export const selectReadonly = createSelector(
  selectPageState,
  (state: PageState) => state.readonly
);
